<template>
  <div class="w-full">
    <div class="flex items-center mb-6 w-full">
      <Title size="large" title="Streamers" />

      <Button kind="secondary" class="ml-auto" label="Invite streamer" @clicked="addContentCreator = true" />
    </div>

    <div v-if="userList" class="flex w-full">
      <List
        class="list--campaigns"
        :sort="[
          {
            label: 'Username',
            prop: 'date',
          },
          {
            label: 'Status',
            prop: 'streamerAmount',
            align: 'center',
          },
          {
            label: 'Language',
            prop: 'streamerAmount',
            align: 'center',
          },
          {
            label: 'Country',
            prop: 'streamerAmount',
            align: 'center',
          },
          {
            label: 'Auto-accept campaigns',
            prop: 'streamerAmount',
            align: 'center',
          },
          {
            label: '',
            prop: '',
          },
        ]"
      >
        <li v-for="(contentCreator, contentCreatorIndex) of userList" :key="contentCreatorIndex" class="list__item">
          <div class="list__item-img">
            <img
              v-if="contentCreator.username"
              v-lazy="
                `https://storage.googleapis.com/cavea-avatar-service/twitch/${contentCreator.username}/avatar.png`
              "
              @error="profileImageFallback"
            />
          </div>

          <div class="list__item-content">
            <div class="list__item-col" data-col="1">
              <Button
                class="mb-2 text-sm font-medium capitalize ellipsis"
                :url="`/profile/${contentCreator.username}`"
                kind="link"
                :label="contentCreator.username"
                style="text-transform: capitalize !important"
                :capitalize="true"
              />

              <p class="text-xs ellipsis">
                Last seen:
                <span v-if="contentCreator && contentCreator.lastLogin">
                  {{ new Date(contentCreator.lastLogin) | moment("Do MMM YYYY") }}
                </span>
                <span v-else> Not signed up</span>
              </p>
            </div>

            <div class="items-center list__item-col" data-col="2">
              <Button
                class="mb-2 text-sm font-medium capitalize button ellipsis"
                kind="link"
                :capitalize="true"
                :label="contentCreator.status || 'N/A'"
              />
            </div>

            <div class="items-center list__item-col" data-col="3">
              <Button
                class="mb-2 text-sm font-medium capitalize button ellipsis"
                kind="link"
                :capitalize="true"
                :label="contentCreator.broadcastingLanguage || 'N/A'"
              />
            </div>

            <div class="items-center list__item-col" data-col="3">
              <Button
                class="mb-2 text-sm font-medium capitalize button ellipsis"
                kind="link"
                :capitalize="true"
                :label="contentCreator.country || 'N/A'"
              />
            </div>

            <div class="items-center list__item-col" data-col="4">
              <Button
                class="mb-2 text-sm font-medium button ellipsis"
                kind="link"
                :capitalize="true"
                :label="contentCreator.autoAcceptCampaigns ? 'Yes' : 'No'"
              />
            </div>

            <div class="list__item-col" data-col="0">
              <Dropdown
                :position="['right']"
                :items="[
                  {
                    label: 'View profile',
                    url: `/profile/${contentCreator.username}`,
                  },
                  {
                    id: `${contentCreator.contentCreatorId}`,
                    label: 'Copy stream code',
                  },
                  {
                    id: `${contentCreator.contentCreatorId}`,
                    label: 'Remove content creator',
                  },
                ]"
                :is-show-more="true"
                @change="dropdownClick"
              />
            </div>
          </div>
        </li>
      </List>
    </div>

    <Modal v-if="addContentCreator" @closeModal="closeAddContentCreator">
      <AddContentCreator @closeAddContentCreator="closeAddContentCreator" />
    </Modal>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { Title, Button } from "cavea-design-system";
import log from "@/lib/log";

export default {
  name: "OrganizationStreamers",

  metaInfo: {
    title: "Streamers",
  },

  components: {
    List: () => import("@/modules/List"),
    Modal: () => import("@/components/Modal"),
    Title,
    Button,
    Dropdown: () => import("@/components/Dropdown"),
    AddContentCreator: () => import("@/components/organization/AddContentCreator"),
  },

  data() {
    return {
      userList: null,
      addContentCreator: false,
    };
  },

  computed: {
    ...mapGetters(["getUserInfo", "getOrganization"]),
  },

  watch: {
    getOrganization: {
      handler() {
        if (this.getOrganization._id) {
          this.fetchUserList();
        }
      },
    },
  },

  created() {
    if (this.getUserInfo?.type === "admin" || this.getUserInfo?.type === "user") {
      if (this.getOrganization?._id === "6104316858d6180c099272b9") {
        this.$router.push("/admin");
        return;
      }

      if (this.getOrganization?._id) {
        this.fetchUserList();
      }
    } else {
      this.$router.push("/");
    }
  },

  methods: {
    async fetchUserList() {
      log("fetchUserList");

      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/organization/owned-content-creators/${this.getOrganization._id}`;

      this.userList = await axios
        .get(URL)
        .then((res) => res.data.streamers)
        .catch((err) => {
          console.error("Error fetching user list", err);
          return [];
        });
    },

    closeAddContentCreator() {
      log("closeAddContentCreator");
      this.addContentCreator = false;

      this.fetchUserList();
    },

    profileImageFallback(event) {
      event.target.src = "https://storage.googleapis.com/cavea-avatar-service/twitch/frymealiiiver/avatar.png";
    },

    dropdownClick(e) {
      // TODO: Create a short label
      if (e?.item?.label?.toLowerCase() === "copy stream code") {
        const tempElement = document.createElement("input");
        const overlayLink = `https://overlay.netwrk.gg/?id=${e.item.id}`;
        tempElement.value = overlayLink;
        document.body.appendChild(tempElement);

        tempElement.select();
        document.execCommand("copy");
        document.body.removeChild(tempElement);
      } else if (e?.item?.label?.toLowerCase() === "remove content creator") {
        this.removeContentCreator(e.item.id);
      }
    },

    async removeContentCreator(contentCreatorId) {
      log({ fn: "removeContentCreator", contentCreatorId });

      const contentCreatorIndex = this.userList?.findIndex((u) => u?.contentCreatorId === contentCreatorId);

      if (typeof contentCreatorIndex === "number" && contentCreatorIndex !== -1) {
        const contentCreatorHandle = this.userList[contentCreatorIndex]?.username;

        const confirmation = await this.$swal({
          title: `Are you sure you want to remove ${contentCreatorHandle} from this organization?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#c13859",
          confirmButtonText: `Yes!`,
        });

        if (confirmation?.isConfirmed) {
          const HOST = process.env.VUE_APP_API_URL;
          const URL = `${HOST}/organization/streamer/remove`;

          await axios
            .put(URL, {
              orgId: this.getOrganization._id,
              contentCreatorId,
            })
            .then(() => {
              this.$swal({
                icon: "success",
                title: `${contentCreatorHandle} has been removed. `,
                showConfirmButton: false,
                timer: 1000,
              });
            })
            .catch((error) => {
              this.$swal({
                icon: "error",
                title: "Something went wrong, please try again. ",
                timer: 800,
                showConfirmButton: false,
              });
              console.error("removeContentCreator error", error);
            });

          this.fetchUserList();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.list--campaigns {
  // Title
  [data-col="1"] {
    width: 20%;
    flex-shrink: 0;
    flex-grow: 1;
  }

  [data-col="2"],
  [data-col="3"],
  [data-col="4"],
  [data-col="5"],
  [data-col="6"] {
    width: calc(22.5% - 1.5rem);
    margin-right: 1.5rem;
    align-items: center;
  }

  // Dropdown
  [data-col="0"] {
    width: 1.25rem;
    margin: 0 1.5rem 0 1.5rem;
  }
}
</style>
