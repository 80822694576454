var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex items-center mb-6 w-full"},[_c('Title',{attrs:{"size":"large","title":"Streamers"}}),_c('Button',{staticClass:"ml-auto",attrs:{"kind":"secondary","label":"Invite streamer"},on:{"clicked":function($event){_vm.addContentCreator = true}}})],1),(_vm.userList)?_c('div',{staticClass:"flex w-full"},[_c('List',{staticClass:"list--campaigns",attrs:{"sort":[
        {
          label: 'Username',
          prop: 'date',
        },
        {
          label: 'Status',
          prop: 'streamerAmount',
          align: 'center',
        },
        {
          label: 'Language',
          prop: 'streamerAmount',
          align: 'center',
        },
        {
          label: 'Country',
          prop: 'streamerAmount',
          align: 'center',
        },
        {
          label: 'Auto-accept campaigns',
          prop: 'streamerAmount',
          align: 'center',
        },
        {
          label: '',
          prop: '',
        } ]}},_vm._l((_vm.userList),function(contentCreator,contentCreatorIndex){return _c('li',{key:contentCreatorIndex,staticClass:"list__item"},[_c('div',{staticClass:"list__item-img"},[(contentCreator.username)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
              ("https://storage.googleapis.com/cavea-avatar-service/twitch/" + (contentCreator.username) + "/avatar.png")
            ),expression:"\n              `https://storage.googleapis.com/cavea-avatar-service/twitch/${contentCreator.username}/avatar.png`\n            "}],on:{"error":_vm.profileImageFallback}}):_vm._e()]),_c('div',{staticClass:"list__item-content"},[_c('div',{staticClass:"list__item-col",attrs:{"data-col":"1"}},[_c('Button',{staticClass:"mb-2 text-sm font-medium capitalize ellipsis",staticStyle:{"text-transform":"capitalize !important"},attrs:{"url":("/profile/" + (contentCreator.username)),"kind":"link","label":contentCreator.username,"capitalize":true}}),_c('p',{staticClass:"text-xs ellipsis"},[_vm._v(" Last seen: "),(contentCreator && contentCreator.lastLogin)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(contentCreator.lastLogin),"Do MMM YYYY"))+" ")]):_c('span',[_vm._v(" Not signed up")])])],1),_c('div',{staticClass:"items-center list__item-col",attrs:{"data-col":"2"}},[_c('Button',{staticClass:"mb-2 text-sm font-medium capitalize button ellipsis",attrs:{"kind":"link","capitalize":true,"label":contentCreator.status || 'N/A'}})],1),_c('div',{staticClass:"items-center list__item-col",attrs:{"data-col":"3"}},[_c('Button',{staticClass:"mb-2 text-sm font-medium capitalize button ellipsis",attrs:{"kind":"link","capitalize":true,"label":contentCreator.broadcastingLanguage || 'N/A'}})],1),_c('div',{staticClass:"items-center list__item-col",attrs:{"data-col":"3"}},[_c('Button',{staticClass:"mb-2 text-sm font-medium capitalize button ellipsis",attrs:{"kind":"link","capitalize":true,"label":contentCreator.country || 'N/A'}})],1),_c('div',{staticClass:"items-center list__item-col",attrs:{"data-col":"4"}},[_c('Button',{staticClass:"mb-2 text-sm font-medium button ellipsis",attrs:{"kind":"link","capitalize":true,"label":contentCreator.autoAcceptCampaigns ? 'Yes' : 'No'}})],1),_c('div',{staticClass:"list__item-col",attrs:{"data-col":"0"}},[_c('Dropdown',{attrs:{"position":['right'],"items":[
                {
                  label: 'View profile',
                  url: ("/profile/" + (contentCreator.username)),
                },
                {
                  id: ("" + (contentCreator.contentCreatorId)),
                  label: 'Copy stream code',
                },
                {
                  id: ("" + (contentCreator.contentCreatorId)),
                  label: 'Remove content creator',
                } ],"is-show-more":true},on:{"change":_vm.dropdownClick}})],1)])])}),0)],1):_vm._e(),(_vm.addContentCreator)?_c('Modal',{on:{"closeModal":_vm.closeAddContentCreator}},[_c('AddContentCreator',{on:{"closeAddContentCreator":_vm.closeAddContentCreator}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }